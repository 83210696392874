import React from "react"
import buttons from "../../styles/buttons.module.css"

type ArticleCardProps = {
  title: string
  dateText: string
  logo: string
  excerpt: string
  article: string
  image: string
  imageAlt: string
  imageTitle: string
}

export const ArticleCard = ({
  title,
  dateText,
  excerpt,
  article,
  image,
  imageAlt,
  imageTitle,
}: ArticleCardProps) => {
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 mb-6 lg:col-span-4 lg:col-start-9">
        <img
          loading="lazy"
          className="w-full"
          data-testid="articleItemImage"
          width="360"
          height="240"
          src={image}
          alt={imageAlt}
          title={imageTitle}
        ></img>
      </div>
      <div className="col-span-12 lg:row-start-1 lg:col-start-1 lg:col-span-7">
        <div className="flex items-center">
          <p className="mt-0 mb-1 overline">{dateText}</p>
        </div>
        <h3 className="mt-0">{title}</h3>
        <p className="mt-2">{excerpt}</p>
        {article && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-block mt-0 ${buttons.btnLargeExpressiveOutline}`}
            href={article}
          >
            Read more
          </a>
        )}
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { ArticleCard } from "../components/ArticleCard"
import { AffiliateList } from "../components/AffiliateList"
import { FilterTags } from "../components/FilterTags"
import containerClasses from "../styles/container.module.css"

const InThePress = ({ data }) => {
  const dataArr = data?.allMarkdownRemark?.edges

  const articlesDestructured = []

  dataArr.map(article => {
    return articlesDestructured.push({
      ...article.node.frontmatter,
    })
  })

  const [articles, setArticlesState] = useState(articlesDestructured)

  const pressCategories = [
    {
      title: "Year",
      uid: "yearNumber",
    },
  ]

  return (
    <Layout page="In The Press">
      <SEO
        title="Miss Elizabeth Hawkes | Media & PR | In The Press"
        description="Miss Hawkes is regularly featured in media including OK!, Sloane Square Magazine, The Sunday Times and more. Read the latest."
      />
      <div className="bg-white">
        <div className={`${containerClasses.container} py-20 `}>
          <div className="col-span-4 md:col-span-8 lg:col-span-12">
            <p className="mt-0 overline">DR HAWKES</p>
            <h1 className="mt-4 fluid-display-02">
              <i>In</i> the Press
            </h1>
          </div>
        </div>
      </div>
      <div className="bg-white border-solid border-borderColour border-t-1">
        <div className={`${containerClasses.container} pt-12 lg:py-24`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-3">
            <FilterTags
              types={pressCategories}
              items={articlesDestructured}
              setItemsState={setArticlesState}
            />
          </div>
          <div className="col-span-4 md:col-span-8 lg:col-span-9 lg:col-start-4">
            {articles.map((article, key) => (
              <div key={`article-${key}`} className="mb-20 lg:mb-10">
                <ArticleCard {...article} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <AffiliateList />
    </Layout>
  )
}

export default InThePress

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/press/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            id
            dateText
            yearNumber
            title
            excerpt
            image
            imageAlt
            imageTitle
            article
          }
        }
      }
    }
  }
`
